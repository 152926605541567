import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Home.css";

function ComingSoonDetails() {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xl={8} lg={8} md={8} sm={12}>
          <Container
            fluid
            style={{ backgroundColor: "black", padding: 0, marginTop: "10%" }}
          >
            <div
              style={{
                backgroundColor: "rgb(196, 46, 46)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "max-content",
              }}
            >
              <h1
                style={{
                  color: "white",
                  paddingLeft: "1%",
                  paddingRight: "1%",
                }}
              >
                What's Next?
              </h1>
            </div>
            <div
              style={{
                backgroundColor: "black",
                color: "white",
                paddingTop: "5%",
                paddingBottom: "15%",
              }}
            >
              <p
                style={{
                  textAlign: "left",
                  marginLeft: "2%",
                  marginRight: "2%",
                }}
              >
                I have been spending a lot of time on real time systems concepts. I implemented my own Real Time OS that has basic memory management and a whole host of scheduling options.
                I have been playing around with a STM32-F446re board where I explored most of these concepts and see how different scheduling algorithms compare. 
                As a result of that I kind of neglected what projects I was planning to work on but I do plan on revisiting them sometime this year.
                <br/><br/>
                I am enrolled in a Parallel Systems and a Real Time Systems class so I am sure I will get all the systems excitement I had the past couple of months out through those classes.
                <br/><br/>
                My goal is to focus on the classes I am in (since they are graduate classes in topics that genuinely interest me) but I want to finish up the projects I have on my site already when I have time.
                For future projects, I have not thought a huge amount about it but something with AI and systems and preferably something more practical (as in I am not just reverse engineering well known algorithms in a simple use case) sounds fun. 
                <br/><br/>

              </p>
            </div>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default ComingSoonDetails;
