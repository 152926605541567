import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./Home.css";

function ConnectFourPage() {
    return (
        <Container>
            <Row className="justify-content-center">
                <Col xl={8} lg={8} md={8} sm={12}>
                    <Container fluid style={{ backgroundColor: "black", padding: 0, marginTop: "10%", marginBottom: "10%" }}>
                        <div style={{ backgroundColor: "rgb(196, 46, 46)", display: "flex", justifyContent: "space-between", alignItems: "center", height: "max-content" }}>
                            <h1 style={{ color: "white", paddingLeft: "1%", paddingRight: "1%" }}>Personal Project: Connect 4 RL AI (In Progress)</h1>
                        </div>
                        <img
                            src={process.env.PUBLIC_URL + "connectFourImage.jpg"}
                            alt="connect four board"
                            style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                maxWidth: "100%",
                                maxHeight: "100%",
                                padding: "2%",
                            }}
                        />                
                        <div style={{ backgroundColor: "black", color: "white", paddingTop: "5%", paddingBottom: "15%" }}>
                            <p style={{ textAlign: "left", marginLeft: "2%", marginRight: "2%" }}>
                                I am developing a Deep Q-Network (DQN) to learn how to play Connect Four at a competitive level against humans. The project involves creating a reinforcement learning (RL) algorithm called Q-learning and integrating it with a neural network to perform function approximation. This will enable the system to better evaluate moves and, hopefully, perform competitively against human players or traditional AI. It's worth noting that Connect Four is a solved game so you can almost always win if you play first which means this project is more about the learning experience than practical application.   
                            </p>

                            <br /><br />
                            <p style={{ textAlign: "left", marginLeft: "2%", marginRight: "2%" }}>
                                Update:
                            </p>
                            <br />
                            <p style={{ textAlign: "left", marginLeft: "2%", marginRight: "2%" }}>
                                I have made a lot of progress on this. I got distracted a lot from stuff you can see in the <Link to="/coming-soon" >Coming Soon</Link> page but I plan to finish this sometime before summer starts.
                                I ended up doing it in C which had a lot of implications like implementing my own NN for the DQN from scratch. The problem now is that the DQN isn't learning enough to outperform minimax (a traditional algorithm used for connect four)
                                and I am currently working on fixing that through trial and error experimentation mostly. I also plan to use Emscripten to make a basic web app so people can visually see the work I did without reading through or understanding code. 
                            </p>


                    { /* <a
                     href="https://github.com/as4230"
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ color: "#fff", textDecoration: "none", padding: "2%"}}
                    onMouseOver={e => e.target.style.color = "#ccc"}
                    onMouseOut={e => e.target.style.color = "#fff"}
                    >
                    <code>You can find more detail on my Github here</code>
                    </a> */}

                        </div>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default ConnectFourPage;