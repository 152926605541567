import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet} from 'react-helmet-async';
import Header from './components/Header';
import HomePage from './components/HomePage';
import Footer from './components/Footer';
import CircuitBoard from './components/CircuitBoard';

function Home() {
  const location = useLocation();
  const currentPage = location.pathname;

  return (
    <div className="App">
      <Helmet>
        <title>Adam Scott | Home</title>
      </Helmet>

      <Header currentPage={currentPage} />
      <CircuitBoard/>
      <HomePage />
      <Footer />
    </div>

  );
}

export default Home;