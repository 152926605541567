import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Home.css";

function RndPuppiesPage() {
    return (
        <Container>
            <Row className="justify-content-center">
                <Col xl={8} lg={8} md={8} sm={12}>
                    <Container fluid style={{ backgroundColor: "black", padding: 0, marginTop: "10%", marginBottom: "10%" }}>
                        <div style={{ backgroundColor: "rgb(196, 46, 46)", display: "flex", justifyContent: "space-between", alignItems: "center", height: "max-content" }}>
                            <h1 style={{ color: "white", paddingLeft: "1%", paddingRight: "1%" }}>A Journey into Full-Stack Development</h1>
                        </div>
                        <img src={process.env.PUBLIC_URL + "rndpuppies.png"} alt="website homepage" style={{ maxWidth: "100%", maxHeight: "100%", padding: "2%" }} />
                        <div style={{ backgroundColor: "black", color: "white", paddingTop: "5%", paddingBottom: "15%" }}>
                            <p style={{ textAlign: "left", marginLeft: "2%", marginRight: "2%" }}>
                                I made a web app for a family member who sells dogs.
                                I used Spring Boot, MySQL, and React to make a simple platform that lets them manage breeds, puppies, parents, litters, and an "About Us" page.
                                <br /><br />
                                Picking React for the frontend was easy.
                                It made adding cool features, like letting the family member edit images and move them around in posts, a lot simpler.
                                <br /><br />
                                To keep the web app safe, I learned about SSL certificates.
                                I even put one together and used it with Spring Boot, so I could host the app on an AWS EC2 instance, keeping everything secure.
                                <br /><br />
                                I found out that web development might not be my thing, but it's still kind of fun.
                                I'll keep the web app going for as long as my family member needs it, making sure it does what they need for their business.
                                <br /><br />
                                I learned a lot from this project, like new skills and more about web development, and I got to make something that really fits what my family member needed.
                            </p>

                            <br /><br /><br /><br />

                            <p style={{ textAlign: "left", marginLeft: "2%", marginRight: "2%", color: "#FF6666" }}>
                                This site is currently down. After a while of it being up, I wasn't really happy with how the frontend looked. It worked, but I wasn't happy with it, and I feel like I could do better.
                                Also, Spring Boot was overkill for such a simple site. I think next time I revisit this, I will do it with Python's Django instead. 
                                Spring Boot is just meant for intense enterprise-level applications, and going through all that overhead for a basic CRUD app isn't worth it. 
                                I plan to revisit this at some point, whether through boredom, a spark of creativity, or pressure from that family member it was originally made for, but I will be back.
                            </p>

                            <a
                                href="https://rndpuppies.com"
                                target="_blank"
                                rel="noreferrer noopener"
                                style={{ color: "#fff", textDecoration: "none", padding: "2%" }}
                                onMouseOver={e => e.target.style.color = "#ccc"}
                                onMouseOut={e => e.target.style.color = "#fff"}
                            >
                                <code>View the site here: https://rndpuppies.com</code>
                            </a>
                        </div>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default RndPuppiesPage;