import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Home.css";

function WebsitePage() {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xl={8} lg={8} md={8} sm={12}>
          <Container
            fluid
            style={{ backgroundColor: "black", padding: 0, marginTop: "10%" }}
          >
            <div
              style={{
                backgroundColor: "rgb(196, 46, 46)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "max-content",
              }}
            >
              <h1
                style={{
                  color: "white",
                  paddingLeft: "1%",
                  paddingRight: "1%",
                }}
              >
                Personal Project: This Website
              </h1>
            </div>
            <div
              style={{
                backgroundColor: "black",
                color: "white",
                paddingTop: "5%",
                paddingBottom: "15%",
              }}
            >
              <p
                style={{
                  textAlign: "left",
                  marginLeft: "2%",
                  marginRight: "2%",
                }}
              >
                I initially developed this website using raw HTML, CSS, and JavaScript. However, I encountered challenges with ensuring proper responsiveness across different devices. 
                To address this, I decided to update the website using React and Bootstrap. 
                Although I was not familiar with React initially, this transition has provided significant benefits and was much easier than trying to make the old version responsive.
                <br/><br/>

                By leveraging React and Bootstrap, my website now is much more responsive and their are a couple neat built-in features such as automatic collapsing of the header on smaller screen sizes. 
                These frameworks have simplified the development process and allowed me to focus more on the website's content and functionality.
                <br/><br/>

                In terms of hosting, I currently utilize Firebase for hosting my website. 
                I mostly like it for its ease of use with the Firebase CLI but it also provides many features like analytics and REST APIs I could use.
                <br/><br/>

                Overall, React + Bootstrap + Firebase = Fun
              </p>
            </div>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default WebsitePage;
