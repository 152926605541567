import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Website from './Website';
// import ClassProject from './ClassProject';
import Editor from './Editor';
import Contact from './Contact';
import { HelmetProvider} from 'react-helmet-async';
import RndPuppies from './RndPuppies';
import ComingSoon from './ComingSoon';
import ConnectFour from './ConnectFour';





const MyRoutes = () => {
  return (
    <HelmetProvider>
    <Routes>
      <Route exact path="/" element={<Home/>} />
      <Route exact path="/website" element={<Website/>} />
      {/* <Route exact path="/class-project" element={<ClassProject/>} /> */}
      <Route exact path="/editor" element={<Editor/>} />
      <Route exact path="/contact" element={<Contact/>} />
      <Route exact path="/crud-app" element={<RndPuppies/>} />
      <Route exact path="/connectfour" element={<ConnectFour/>} />
      <Route exact path="/coming-soon" element={<ComingSoon/>} />

    </Routes>
    </HelmetProvider>
  );
};

export default MyRoutes;