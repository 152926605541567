import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Home.css";

function HomePage() {
  return (
    <Container>
        <Row className="justify-content-center">
            <Col xl={8} lg={8} md={8} sm={12}>
                <Container fluid style={{ backgroundColor: "black", padding: 0, marginTop: "10%"}}>
                <div style={{ backgroundColor: "rgb(196, 46, 46)", display: "flex", justifyContent: "space-between", alignItems: "center", height: "max-content" }}>
                    <h1 style={{ color: "white", paddingLeft: "1%", paddingRight: "1%"}}>About me: Adam Scott</h1>
                    <img src={process.env.PUBLIC_URL + "outdoor.jpg"} alt="Proof that I go outside" style={{ maxWidth: "40%", maxHeight: "40%" }} />
                </div>
                <div style={{ backgroundColor: "black", color: "white", paddingTop: "5%", paddingBottom: "15%"}}>
                    <p style={{ textAlign: "left", marginLeft: "2%", marginRight: "2%"}}>
                    Hey, my name is Adam Scott. I am a computer science major at North Carolina State University and I am
                    planning to graduate in May 2024. My plan after graduation is to go get a master's in CS. I spend a lot of my time trying to make perfect grades but outside of that
                    I try to work on personal projects like this website.
                    I also spend my time playing video games when I am not doing something productive.
                    </p>
                </div>
                </Container>
            </Col>
        </Row>
    </Container>
  );
}

export default HomePage;