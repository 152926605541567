import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Home.css";

function EditorPage() {
  return (
    <Container>
        <Row className="justify-content-center">
            <Col xl={8} lg={8} md={8} sm={12}>
                <Container fluid style={{ backgroundColor: "black", padding: 0, marginTop: "10%", marginBottom: "10%"}}>
                <div style={{ backgroundColor: "rgb(196, 46, 46)", display: "flex", justifyContent: "space-between", alignItems: "center", height: "max-content" }}>
                    <h1 style={{ color: "white", paddingLeft: "1%", paddingRight: "1%"}}>Personal Project: Toy Code Editor With Suggestions</h1>
                </div>
                <img src={process.env.PUBLIC_URL + "editor.png"} alt="code editor" style={{ maxWidth: "100%", maxHeight: "100%", padding: "2%" }} />
                <div style={{ backgroundColor: "black", color: "white", paddingTop: "5%", paddingBottom: "15%"}}>
                    <p style={{ textAlign: "left", marginLeft: "2%", marginRight: "2%"}}>
                    I developed a Python code editor that runs in the browser using Skulpt. To enhance the editor's user experience, I implemented a suggestion algorithm based on Markov chains. By training five orders of Markov chains on a dataset of LeetCode answers, I ensured the suggestions are relevant to the user's code. Additionally, I compiled the Markov chains as WebAssembly modules using Emscripten for faster loading. To further boost performance, I utilized a web worker to handle Markov chain token generation in a separate thread. This project allowed me to explore various techniques for improving code editor performance and functionality while gaining insights into Markov chains for generating suggestions.                    </p>

                    <a
                     href="https://github.com/as4230"
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ color: "#fff", textDecoration: "none", padding: "2%"}}
                    onMouseOver={e => e.target.style.color = "#ccc"}
                    onMouseOut={e => e.target.style.color = "#fff"}
                    >
                    <code>You can find more detail on my Github here</code>
                    </a>
                </div>
                </Container>
            </Col>
        </Row>
    </Container>
  );
}

export default EditorPage;