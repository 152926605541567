import './components/Home.css';
import Header from './components/Header';
import WebsitePage from './components/WebsitePage';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import CircuitBoard from './components/CircuitBoard';
import Footer from './components/Footer';

function Website() {
  const location = useLocation();
  const currentPage = location.pathname;
  
  return (
    <div className="App">
      <Helmet>
        <title>Adam Scott | Website</title>
      </Helmet>
      <Header currentPage={currentPage} />
      <CircuitBoard/>
      <WebsitePage />
      <Footer />

    </div>
  );
}

export default Website;